
import PhotoSwipeLightbox from '/static/js/photoswipe-lightbox.esm.min.js';

var gallery_selector = '#portfolio'
var child_selector = 'a'

export function initGallery(){
  $(gallery_selector + " " + child_selector).each(function () {
    var img = $(this).children(":first");

    $(this).attr("data-pswp-width", img.prop("naturalWidth"))
    $(this).attr("data-pswp-height", img.prop("naturalHeight"))
    $(this).attr("data-pswp-src", img.prop("src"))

  })

  const lightbox = new PhotoSwipeLightbox({
    // may select multiple "galleries"
    gallery: gallery_selector,

    // Elements within gallery (slides)
    children: child_selector,

    // setup PhotoSwipe Core dynamic import
    pswpModule: () => import('/static/js/photoswipe.esm.min.js')
  });
  lightbox.init();
}