import { navbarDropdown } from "./main.js";
import {initGallery} from "./photo-gallery.js";
import {initSlider} from "./slick-slider.js";
import { initHeroes, heroScroll } from "./hero-sections.js";

$(document).ready(function () {
  navbarDropdown()
  initHeroes()
  initSlider()
})

$(window).on("load", function (){
  initGallery();
});

window.addEventListener("resize", function () {
  navbarDropdown()
  initHeroes()
}, {passive:true});


window.addEventListener("orientationchange", function () {
  navbarDropdown()
  initHeroes()
}, { passive: true });


window.addEventListener("scroll", function () {
  heroScroll()
}, { passive: true })


