/* PRIMARY HEADER NAV POP-UP MENU */

const primaryHeader = document.querySelector(".primary-header");
const navToggle = document.querySelector(".mobile-nav-toggle");
const primaryNav = document.querySelector(".primary-navigation");


navToggle.addEventListener("click", () => {
  primaryNav.hasAttribute("data-visible")
    ? navToggle.setAttribute("area-expanded", false)
    : navToggle.setAttribute("area-expanded", true);
  primaryNav.toggleAttribute("data-visible");
  primaryHeader.toggleAttribute("data-overlay");
}, {passive: true});

const navLinks = document.querySelectorAll(".primary-navigation .nav-link")

navLinks.forEach(function (item) {
  // console.log("Adding event for ", item)
  item.addEventListener('click', () => {
    primaryNav.hasAttribute("data-visible")
      ? navToggle.setAttribute("area-expanded", false)
      : navToggle.setAttribute("area-expanded", true);
    primaryNav.toggleAttribute("data-visible");
    primaryHeader.toggleAttribute("data-overlay");
  }, {passive: true});
});


$('.alert').on('close.bs.alert', function () {
  console.log('Hide element', $(this))
  $(this).hide()
  return false;
})

function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}


export function navbarDropdown(){
  
  if (window.innerWidth > convertRemToPixels(47)) {
      document.querySelectorAll('.primary-navigation .nav-item.dropdown').forEach(function (everyitem) {
        let myTimeout;
        everyitem.addEventListener('mouseover', function (e) {
          clearTimeout(myTimeout)
          let el_link = this.querySelector('a[data-bs-toggle]');
          
          if (el_link != null) {
            let nextEl = el_link.nextElementSibling;
            el_link.classList.add('show');
            nextEl.classList.add('show');
          }
          
          el_link.addEventListener("click", function () {
            location.href = this.getAttribute("href")
          })
          
        });
      everyitem.addEventListener('mouseleave', async function (e) {
        let el_link = this.querySelector('a[data-bs-toggle]')
        if (el_link != null) {
          let nextEl = el_link.nextElementSibling;
          myTimeout  =  setTimeout(function(){
            el_link.classList.remove('show');
            nextEl.classList.remove('show');

          }, 650);

        }
        

      })
    });

  } else {
    document.querySelectorAll('.primary-navigation .nav-item.dropdown').forEach(function (everyitem) {
    everyitem.addEventListener('click', function () {
      
      let el_link = this.querySelector('a[data-bs-toggle]');
      
      if (el_link.getAttribute("aria-expanded")) {
        
        el_link.addEventListener("click", function () {
          location.href = this.getAttribute("href")
        })
      }

    });
  })
}

}






