export function initSlider(){
  var gadgetCarousel = $(".slider");
  gadgetCarousel.each(function () {
    if ($(this).is(".slider-autoplay")) {
      
      $(this).slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        ceneterMode: true,
        centerPadding: '20%',
        autoplay: true,
        arrows: true,
        infinite: true,
        autoplaySpeed: 3000,
        lazyLoad: 'progressive',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
            }
          },
          {
            breakpoint: 752,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrows: false
            }
          },
        ]
      });
      let content = $(this).find('.slider-content');
      console.log("Slider-Contents: ",content)
      content.each(function () {
        // const parent = $(this)
        const buttons = $(this).find('.button');
        buttons.each(function() {
          $(this).on('click', function() {
            const parent = $(this).parent().parent();
            const data_wrap = parent.attr('data-wrap');
            parent.attr('data-wrap', data_wrap === 'true' ? 'false': 'true')
          });
        });
      });
    };

    if ($(this).is(".casos-autoplay")) {
      console.log("iniate auto play")
      $(this).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        fade: true,
        cssEase: 'linear',
        lazyLoad: 'progressive',
        // variableWidth: true,
        responsive: [
          {
            breakpoint: 752,
            settings: {
              dots: true,
              arrows: false
            }
          },
        ]
      });
    }


  })
};