// Get an element's distance from the top of the page
function getElemDistance(elem) {
  var location = 0;
  if (elem.offsetParent) {
    do {
      location += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
  }
  return location >= 0 ? location : 0;
};

function imgRatio(elem) {
  let ogWidth = elem.naturalWidth;
  let ogHeight = elem.naturalHeight;

  let ogRatio = ogWidth / ogHeight;
  return ogRatio
}


function initElemSize(elem){
  let img = elem.firstElementChild;
  var ogRatio = imgRatio(img);
  var onscreenRatio
  var base_width
  var elem = elem
  onscreenRatio = elem.clientWidth / elem.clientHeight;
  base_width = Math.max((ogRatio / onscreenRatio)  * 100, 100)

  return base_width;
}

function createHeroElem(elem, growth_fctr, scroll){
  let top_dist = getElemDistance(elem)
  let base_width = initElemSize(elem);
  
  var screen_height = $(window).height();
  if (top_dist < screen_height) {
    top_dist = 0
  } else {
    top_dist -= window.innerHeight;
  }

  let abs_base_height = elem.clientHeight;
  let growth = growth_fctr / abs_base_height;
  var hero_elem = {
    html_elem: elem,
    base_width: base_width,
    top_dist: top_dist,
    growth: growth,
    abs_base_height: abs_base_height
  }

  dynamicSizing(hero_elem, scroll)
  return hero_elem
}

function dynamicSizing(hero_elem, scroll_dist){
  var elem_dyn_width = Math.max(hero_elem.base_width * (1 + Math.max(scroll_dist - hero_elem.top_dist, 0) * hero_elem.growth/100), hero_elem.base_width)
  hero_elem.html_elem.style =  "width : " + elem_dyn_width + "%";
}


var heroes = []

export function initHeroes(){
  var scroll = document.documentElement.scrollTop || document.body.scrollTop;

  var heroes_temp = $(".hero-image");
  heroes_temp.each(function () {
    if ($(this).is("#main-hero")) {
      heroes.push(createHeroElem($(this)[0], 30, scroll));
    }
    if ($(this).is("#service-hero")) {
      heroes.push(createHeroElem($(this)[0], 30, scroll));
    }
    if ($(this).is("#service-section-hero")) {
      heroes.push(createHeroElem($(this)[0], 10, scroll));
    }
    if ($(this).is("#patients-hero")) {
      heroes.push(createHeroElem($(this)[0], 30, scroll));
    }
  })
  
}

export function heroScroll(){
  var scroll = document.documentElement.scrollTop || document.body.scrollTop;
  // console.log(heroes)
  heroes.forEach(element => dynamicSizing(element, scroll))
}
